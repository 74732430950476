<template>
    <div class="filter-container">
        <div class="label">
            {{ $t('bills.filter_by') }}
        </div>
        <div class="filter-items">
            <img class="filter-icon" src="../../../../../public/img/location_filter.svg" />
            <v-autocomplete v-if="dates" :items="Object.values(restaurants)" v-model="currentFilters.restaurants" :item-text="(item) => (item.code != 'all' ? `${item.code} - ${cleanString(item.name)}` : item.name)" item-value="id" class="select">
                <template slot="selection" slot-scope="data">
                    {{ data.item.code != 'all' ? `${data.item.code} - ${data.item.name}` : $t(data.item.name) }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ data.item.code != 'all' ? `${data.item.code} - ${data.item.name}` : $t(data.item.name) }}
                </template>
            </v-autocomplete>
        </div>
        <div class="filter-items">
            <img class="filter-icon" src="../../../../../public/img/user_icon.svg" />
            <v-autocomplete v-if="dates" :items="Object.values(customers)" v-model="currentFilters.customers" class="select">
                <template slot="selection" slot-scope="data">
                    {{ $t(data.item) }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ $t(data.item) }}
                </template>
            </v-autocomplete>
        </div>
        <div class="filter-items">
            <img class="filter-icon" src="../../../../../public/img/calendar_filter.svg" />
            <v-select v-if="dates" :items="Object.values(dates)" v-model="currentFilters.date" item-text="name" item-value="code" class="select">
                <template slot="selection" slot-scope="data">
                    {{ $t(data.item.name) }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ $t(data.item.name) }}
                </template>
            </v-select>
            <div class="custom" v-if="currentFilters.date == 'custom'">
                <div class="description">{{ $t('bills.from') }}:</div>
                <input class="date-input" v-model="currentFilters.start_date" type="date" />
                <div class="description">{{ $t('bills.to') }}:</div>
                <input class="date-input" :min="currentFilters.start_date" v-model="currentFilters.end_date" type="date" />
            </div>
        </div>
        <div class="filter-actions">
            <div class="cancel" v-if="JSON.stringify(currentFilters) != JSON.stringify(emptyFilters)" @click="resetFilters()">{{ $t('bills.reset_filters') }}</div>
            <div class="filter" v-if="changesDetected" @click="applyFilters()">{{ $t('bills.filter') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BillFilter',
    data() {
        return {
            dateSelected: false,
            currentFilters: {},
            emptyFilters: {
                date: 'month',
                restaurants: 'admin.filters.all_restaurants',
                customers: 'admin.filters.all_customers'
            },
            activeFilters: {},
            changesDetected: false
        }
    },
    computed: {
        dates() {
            return this.$store.getters['bills/getBillsFilters'].date
        },
        restaurants() {
            return this.$store.getters['bills/getBillsFilters'].restaurants
        },
        customers() {
            return this.$store.getters['bills/getBillsFilters'].customers
        }
    },
    methods: {
        load() {
            this.applyFilters()
        },
        resetFilters() {
            var self = this
            this.$overlay.show()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            this.start_date = ''
            this.end_date = ''
            this.$store.commit('bills/setBillsFiltersActive', this.currentFilters)
            this.$store.dispatch('bills/loadBills', { date: 'month', restaurants: '' }).then(function () {
                self.$emit('resetFilters')
                self.$overlay.hide()
            })
        },
        applyFilters() {
            var self = this
            this.$overlay.show()
            this.$store.commit('bills/setBillsFiltersActive', this.currentFilters)
            var params = { ...this.currentFilters }
            if (params.restaurants == 'admin.filters.all_restaurants') {
                params.restaurants = ''
            }
            if (params.customers == 'admin.filters.all_customers') {
                params.customers = ''
            }
            this.$store.dispatch('restaurant/loadRestaurants').then(function () {
                self.$store.dispatch('bills/loadBills', params).then(function () {
                    self.changesDetected = false
                    self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
                    self.$emit('applyFilters')
                    self.$overlay.hide()
                })
            })
        },
        cleanString(string) {
            // var r=string.toLowerCase();
            // r = r.replace(new RegExp(/\s/g),"");
            var r = string
            r = r.replace(new RegExp(/[àáâãäå]/g), 'a')
            r = r.replace(new RegExp(/[ÀÁ]/g), 'A')
            r = r.replace(new RegExp(/[èéêë]/g), 'e')
            r = r.replace(new RegExp(/[ÈÉ]/g), 'E')
            r = r.replace(new RegExp(/[ìíîï]/g), 'i')
            r = r.replace(new RegExp(/[ÌÍ]/g), 'I')
            r = r.replace(new RegExp(/ñ/g), 'n')
            r = r.replace(new RegExp(/[òóôõö]/g), 'o')
            r = r.replace(new RegExp(/[ÒÓ]/g), 'O')
            r = r.replace(new RegExp(/[ùúûü]/g), 'u')
            r = r.replace(new RegExp(/[ÙÚ]/g), 'U')
            r = r.replace(new RegExp(/[ñ]/g), 'n')
            r = r.replace(new RegExp(/[Ñ]/g), 'N')
            return r
        }
    },
    created() {},
    mounted() {
        this.activeFilters = { ...this.$store.getters['bills/getBillsFiltersActive'] }
        this.currentFilters = { ...this.$store.getters['bills/getBillsFiltersActive'] }
        this.load()
    },
    watch: {
        currentFilters: {
            handler: function () {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)) {
                    this.changesDetected = true
                } else {
                    this.changesDetected = false
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss"></style>
