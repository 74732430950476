<template>
    <div class="layout-page">
        <div class="summary-container">
            <div class="summary-info">
                <div class="bills-generated">
                    <div class="title">
                        {{ $t('bills.bills_generated') }}
                    </div>
                    <div class="number">{{ Object.values(items).length }}</div>
                </div>
                <div class="bills-generated">
                    <div class="title">{{ $t('bills.clients') }}</div>
                    <div class="number" v-if="customers">{{ Object.values(customers).length }}</div>
                </div>
                <div class="bills-generated">
                    <div class="title">{{ $t('bills.import') }}</div>
                    <div class="number">{{ importMoney + '€' }}</div>
                </div>
            </div>
            <div class="summary-actions">
                <FilterBills v-on:applyFilters="resetSelectedItems()" v-on:resetFilters="resetSelectedItems()"></FilterBills>
                <div class="table-actions">
                    <div class="export">
                        <div class="export-button" @click="exportButton = !exportButton">{{ selected.length > 0 ? $t('bills.export') : $t('bills.export_all') }}</div>
                        <transition name="fade">
                            <div class="export-box" v-if="exportButton">
                                <div class="text" @click="downloadPDF()">{{ $t('bills.export_as_pdf') }}</div>
                                <div class="text" @click="downloadEXCEL()">{{ $t('bills.export_as_xlsx') }}</div>
                                <div class="text" @click="downloadXML()">{{ $t('bills.export_as_xml') }}</div>
                            </div>
                        </transition>
                    </div>
                    <div class="search">
                        <input type="text" v-model="search" class="input-search" name="name" />
                    </div>
                </div>
            </div>
        </div>
        <div class="data-table">
            <v-data-table @toggle-select-all="allSelected = !allSelected" v-if="items" v-model="selected" :headers="headers" fixed-header :search="search" :items="Object.values(items)" sort-by="name" :single-select="singleSelect" :sort-asc="true" hide-default-footer show-select :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pageCount = $event">
                <template #item.ticket_number="item">
                    <div class="ticket" :class="{ canceled: item.item.status == -2 }">{{ '#' + item.item.ticket_number }}</div>
                </template>
                <template #item.date="item">
                    <div class="row" :class="{ canceled: item.item.status == -2 }">{{ moment(item.item.date * 1000).format('DD/MM/YYYY') }}</div>
                </template>
                <template #item.price="item">
                    <div class="row" :class="{ canceled: item.item.status == -2 }">{{ parseFloat(item.item.price).toFixed(2) + '€' }}</div>
                </template>
                <template #item.restaurant="item">
                    <div class="row" :class="{ canceled: item.item.status == -2 }">{{ restaurants(item.item.restaurant).code + ' - ' + restaurants(item.item.restaurant).name }}</div>
                </template>
                <template #item.customer_name="item">
                    <div class="row" :class="{ canceled: item.item.status == -2 }">{{ item.item.customer_name + ' - ' + item.item.customer_fiscal_id }}</div>
                </template>
                <template #item.file="item">
                    <div class="row-image" v-if="item.item.file">
                        <a target="_blank" :href="item.item.file"><img class="icon" src="../../public/img/pdf_icon.svg" /></a>
                    </div>
                </template>
                <template #item.email="item">
                    <div class="row button" v-if="item.item.file && item.item.email && item.item.status == 1" @click="showResendBillPopup(item.item)">{{ $t('bills.resend') }}</div>
                </template>
                <template #item.id="item">
                    <div class="row button" v-if="item.item.status == 1" @click="regenerateBill(item.item)">{{ $t('bills.regenerate') }}</div>
                </template>
                <template #item.status="item">
                    <div class="row button" v-if="item.item.status == 1" @click="showCancelInvoicePopup(item.item)">{{ $t('bills.cancel_invoice') }}</div>
                    <div class="row invoice_canceled" v-else-if="item.item.status == -2">{{ $t('bills.invoice_canceled') }}</div>
                </template>
                <template v-slot:no-data>
                    <div class="no-data">
                        {{ $t('bills.table_no_data') }}
                    </div>
                </template>
            </v-data-table>
            <div id="pagination">
                <div class="totalItems">
                    <span class="total">
                        <strong class="total-results">{{ Object.values(items).length }}</strong>
                        {{ $tc('bills.results', Object.values(items).length) }}
                    </span>
                </div>
                <v-pagination v-model="pagination.page" color="#C4C4C4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
                <div class="rows-page">
                    <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                </div>
            </div>
        </div>
        <Modal v-if="showModal" :type="modelType" :invoiceNumber="selectedInvoiceNumber" :email="selectedBillEmail" @close="closePopup()" @confirm="handleConfirmPopup()"></Modal>
    </div>
</template>

<script>
import FilterBills from '../components/layout/admin/bills/Filter.vue'
import Modal from '@/components/ui/Modal.vue'
export default {
    components: { FilterBills, Modal },
    name: 'Bills',
    data() {
        return {
            search: '',
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            allSelected: false,
            exportButton: false,
            singleSelect: false,
            valueFilter: 1,
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            selected: [],
            maxPageVisibles: 10,
            showModal: false,
            modelType: '',
            selectedInvoiceNumber: '',
            selectedBillEmail: ''
        }
    },
    computed: {
        items() {
            const bills = this.$store.getters['bills/getBills']()
            const sortedBills = Object.values(bills).sort((elementA, elementB) => {
                let result = 0

                if (elementA.date > elementB.date) {
                    result = -1
                }

                if (elementA.date < elementB.date) {
                    result = 1
                }

                if (elementA.date == elementB.date) {
                    result = this.sortBillNumber(elementA.ticket_number, elementB.ticket_number)
                }

                return result
            })
            return sortedBills
        },
        nRegistros() {
            var letter = this.$t('bills.show_bills')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        headers() {
            return [
                {
                    text: this.$t('bills.bill_number'),
                    align: 'start',
                    sortable: true,
                    sort: this.sortBillNumber,
                    value: 'ticket_number',
                    width: 'auto',
                    class: 'header-table'
                },
                {
                    text: this.$t('bills.emision_date'),
                    value: 'date',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('bills.import'),
                    value: 'price',
                    align: 'start',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('bills.restaurant'),
                    value: 'restaurant',
                    align: 'start',
                    width: 'auto',
                    class: 'header-table'
                },
                {
                    text: this.$t('bills.user'),
                    value: 'customer_name',
                    align: 'start',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: '',
                    value: 'file',
                    align: 'start',
                    width: '6px',
                    sortable: false,
                    class: 'header-table'
                },
                {
                    text: '',
                    value: 'email',
                    align: 'start',
                    width: '6px',
                    sortable: false,
                    class: 'header-table'
                },
                {
                    text: '',
                    value: 'id',
                    align: 'start',
                    width: '6px',
                    sortable: false,
                    class: 'header-table'
                },
                {
                    text: '',
                    value: 'status',
                    align: 'start',
                    width: '6px',
                    sortable: false,
                    class: 'header-table'
                }
            ]
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        importMoney() {
            var number = 0
            if (this.items) {
                Object.values(this.items).forEach(function (numero) {
                    number += numero.price
                })
            }
            return parseFloat(number).toFixed(2)
        },
        customers() {
            var tempResult = {}
            if (this.items) {
                var arr = Object.values(this.items)

                for (let { customer_fiscal_id } of arr)
                    tempResult[customer_fiscal_id] = {
                        customer_fiscal_id,
                        count: tempResult[customer_fiscal_id] ? tempResult[customer_fiscal_id].count + 1 : 1
                    }

                let result = Object.values(tempResult)

                return result
            }
            return tempResult
        }
    },
    methods: {
        load() {
            this.$store.dispatch('init')
            this.$store.dispatch('bills/loadFiltersBills').then(function () {})
        },
        restaurants(id) {
            if (id) {
                var restaurant = this.$store.getters['restaurant/getRestaurants'](id)
                if (restaurant) {
                    return restaurant
                }
            }
        },
        downloadPDF() {
            var parameters = {}
            const filtersActive = this.$store.getters['bills/getBillsFiltersActive']

            if (Object.values(this.selected) == 0 || this.allSelected) {
                parameters.all = 1
            } else {
                var ids = []
                for (var bill in Object.values(this.selected)) {
                    var idx = Object.values(this.selected)[bill]
                    if (idx) {
                        ids.push(idx.id)
                    }
                }
                parameters.bills = ids.toString()
            }

            if (filtersActive.customers != 'admin.filters.all_customers') {
                parameters.customers = filtersActive.customers
            }

            if (typeof filtersActive.date !== 'undefined') {
                parameters.date = filtersActive.date
            }

            if (typeof filtersActive.start_date !== 'undefined') {
                parameters.start_date = filtersActive.start_date
            }

            if (typeof filtersActive.end_date !== 'undefined') {
                parameters.end_date = filtersActive.end_date
            }

            if (filtersActive.restaurants != 'admin.filters.all_restaurants') {
                parameters.restaurants = filtersActive.restaurants
            }

            this.$overlay.show()
            const self = this
            this.$store.dispatch('bills/downloadPDF', parameters).then(function (response) {
                self.exportButton = false
                self.$overlay.hide()
                window.open(response, 'blank')
            })
        },
        downloadEXCEL() {
            var parameters = {}
            const filtersActive = this.$store.getters['bills/getBillsFiltersActive']

            if (Object.values(this.selected) == 0 || this.allSelected) {
                parameters.all = 1
            } else {
                var ids = []
                for (var bill in Object.values(this.selected)) {
                    var idx = Object.values(this.selected)[bill]
                    if (idx) {
                        ids.push(idx.id)
                    }
                }
                parameters.bills = ids.toString()
            }

            if (filtersActive.customers != 'admin.filters.all_customers') {
                parameters.customers = filtersActive.customers
            }

            if (typeof filtersActive.date !== 'undefined') {
                parameters.date = filtersActive.date
            }

            if (typeof filtersActive.start_date !== 'undefined') {
                parameters.start_date = filtersActive.start_date
            }

            if (typeof filtersActive.end_date !== 'undefined') {
                parameters.end_date = filtersActive.end_date
            }

            if (filtersActive.restaurants != 'admin.filters.all_restaurants') {
                parameters.restaurants = filtersActive.restaurants
            }

            this.$overlay.show()

            const self = this
            this.$store.dispatch('bills/downloadEXCEL', parameters).then(function (response) {
                self.exportButton = false
                self.$overlay.hide()
                window.open(response, 'blank')
            })
        },
        downloadXML() {
            const parameters = {}
            const filtersActive = this.$store.getters['bills/getBillsFiltersActive']

            if (Object.values(this.selected) == 0 || this.allSelected) {
                parameters.all = 1
            } else {
                const ids = []

                for (let bill in Object.values(this.selected)) {
                    const idx = Object.values(this.selected)[bill]

                    if (idx) {
                        ids.push(idx.id)
                    }
                }

                parameters.bills = ids.toString()
            }

            if (filtersActive.customers != 'admin.filters.all_customers') {
                parameters.customers = filtersActive.customers
            }

            if (typeof filtersActive.date !== 'undefined') {
                parameters.date = filtersActive.date
            }

            if (typeof filtersActive.start_date !== 'undefined') {
                parameters.start_date = filtersActive.start_date
            }

            if (typeof filtersActive.end_date !== 'undefined') {
                parameters.end_date = filtersActive.end_date
            }

            if (filtersActive.restaurants != 'admin.filters.all_restaurants') {
                parameters.restaurants = filtersActive.restaurants
            }

            this.$overlay.show()

            const self = this
            this.$store.dispatch('bills/downloadXML', parameters).then((response) => {
                self.exportButton = false
                self.$overlay.hide()
                window.open(response, 'blank')
            })
        },
        sortBillNumber(elementA, elementB) {
            let result = 0

            const elementATicketNumber = elementA.split('MCD')
            const elementBTicketNumber = elementB.split('MCD')

            elementATicketNumber[0] = parseInt(elementATicketNumber[0])
            elementATicketNumber[1] = parseInt(elementATicketNumber[1])

            elementBTicketNumber[0] = parseInt(elementBTicketNumber[0])
            elementBTicketNumber[1] = parseInt(elementBTicketNumber[1])

            if (elementATicketNumber[0] > elementBTicketNumber[0]) {
                result = -1
            }

            if (elementATicketNumber[0] < elementBTicketNumber[0]) {
                result = 1
            }

            if (elementATicketNumber[0] == elementBTicketNumber[0]) {
                if (elementATicketNumber[1] > elementBTicketNumber[1]) {
                    result = -1
                }

                if (elementATicketNumber[1] < elementBTicketNumber[1]) {
                    result = 1
                }
            }

            return result
        },
        resetSelectedItems() {
            this.selected = []
            this.exportButton = false
        },
        showResendBillPopup(bill) {
            this.modelType = 'confirmResend'
            this.selectedInvoiceNumber = bill.ticket_number
            this.selectedBillEmail = bill.email
            this.showModal = true
        },
        async resendBill() {
            try {
                this.$overlay.show()
                await this.$store.dispatch('bills/resendBill', { invoiceNumber: this.selectedInvoiceNumber })
                this.$overlay.hide()
                this.modelType = 'resendBill'
                this.showModal = true
            } catch (error) {
                console.error(error)
            }
        },
        async regenerateBill(bill) {
            try {
                this.$overlay.show()
                await this.$store.dispatch('bills/regenerateBill', { invoiceNumber: bill.id })
                this.$overlay.hide()
                this.modelType = 'regenerateBill'
                this.showModal = true
            } catch (error) {
                console.error(error)
            }
        },
        showCancelInvoicePopup(bill) {
            this.modelType = 'cancelInvoice'
            this.selectedInvoiceNumber = bill.ticket_number
            this.showModal = true
        },
        async cancelInvoice() {
            try {
                this.$overlay.show()
                await this.$store.dispatch('bills/cancelBill', { invoiceNumber: this.selectedInvoiceNumber })
            } catch (error) {
                console.error(error)
            } finally {
                this.closePopup()
                this.$overlay.hide()
            }
        },
        handleConfirmPopup() {
            switch (this.modelType) {
                case 'cancelInvoice':
                    this.cancelInvoice()
                    break
                case 'confirmResend':
                    this.resendBill()
                    break
            }
        },
        closePopup() {
            this.modelType = ''
            this.showModal = false
            this.selectedInvoiceNumber = ''
            this.selectedBillEmail = ''
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="scss">
.search {
    .input-search {
        background-image: url('../../public/img/searcher.svg');
    }
}
.button {
    background-color: #ccc;
    padding: 8px 8px 0 8px;
    border-radius: 4px;
    height: 40px;
    text-align: center;
}

.canceled {
    color: #b3b3b3;
}
</style>
